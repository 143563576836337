// import room1 from "./images/details-1.jpeg";
import room2 from "./images/land.jpg";
import room3 from "./images/land1.JPG";
import room4 from "./images/land2.jpg";
import img1 from "./images/onshow.jpeg";
import img2 from "./images/apartment12.jpg";
// import img3 from "./images/room-3.jpeg";
// import img4 from "./images/room-4.jpeg";
import img5 from "./images/apartment2.jpg";
import img6 from "./images/apartment1.jpeg";
import img7 from "./images/apartment6.jpg";
// import img8 from "./images/apartment11.jpg";
// import img9 from "./images/apartment10.jpg";
// import img10 from "./images/room-10.jpeg";
// import img11 from "./images/room-11.jpeg";
// import img12 from "./images/room-12.jpeg";

export default [
  {
    sys: {
      id: "1"
    },
    fields: {
      name: "Jumanji Eco Estate",
      slug: "estate",
      type: "property",
      price: 745000,
      size: 10000,
      capacity: 4,
      pets: false,
      breakfast: false,
      featured: true,
      description:
        "A distinctive development close to Bronkhorstspruit dam, master-planned in natural surroundings promising a superb lifestyle experience. The estate comprises a superior selection of spacious stands (all larger than 1 ha in size), solid security with modern services and amenities. Exclusive walking trails, dedicated equine bridle paths, a stunning river front area, abundant birdlife as well as a number of free ranging game species ensure a tranquil country lifestyle",
      extras: [

        "Jumanji Eco Estate in Bronkhorstbaai - Approx 35 Stands of 1 Hectare ",
        "One of the best-kept property secrets revealed!"


      ],
      images: [
        {
          fields: {
            file: {
              url: img1
            }
          }
        },
        {
          fields: {
            file: {
              url: room2
            }
          }
        },
        {
          fields: {
            file: {
              url: room3
            }
          }
        },
        {
          fields: {
            file: {
              url: room4
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "2"
    },
    fields: {
      name: "IQ Brooklyn",
      slug: "apartment",
      type: "property",
      price: 1197000,
      size: 48,
      capacity: 1,
      pets: false,
      breakfast: false,
      featured: false,
      description:
        "IQ Brooklyn is a prestigious new development , which will undoubtedly raise the benchmark in luxury apartments for students and young professionals.In keeping with leading advances and research in global architecture, iQ Brooklyn incorporates the foremost worldwide trends and energy- saving design.Modern lifestyles demand progressive thinking in design architecture, that’s why iQ Brooklyn comes standard with facilities like optical fibre internet access, central DSTV, a laundry service and Starbucks- style lounge and cafeteria. This Development offers an unrivalled Investment Opportunity! 100 Appartments",
      extras: [
        "fibre internet access",
        "central DSTV",
        "laundry service",
        "Starbucks- style lounge and cafeteria",
        "Adequate safety/security",
        "Internet",
        "Investment Opportunity"
      ],
      images: [
        {
          fields: {
            file: {
              url: img2
            }
          }
        },
        {
          fields: {
            file: {
              url: img5
            }
          }
        },
        {
          fields: {
            file: {
              url: img6
            }
          }
        },
        {
          fields: {
            file: {
              url: img7
            }
          }
        }
      ]
    }
  }


]


