import React from 'react';
import Banner from '../components/Banner';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hero from "../components/Hero";
import Email from '@material-ui/icons/MailOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';


function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_mivoqkd', 'contact_us', e.target, 'user_i9yJwSJkY5auhopOIpQ8L')
        .then((result) => {
            console.log(result.text);
            Swal.fire({
                icon: 'success',
                title: 'Message Sent Successfully'
            })
        },
            (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops, please enter the correct details',
                    text: error.text,
                })
            })
    e.target.reset()
};
// const validate = () => {
//     let val = {}
//     val.fullName = val.fullName ? "" : "This field is required.";

// }


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}

            Property Marketing Pty Ltd
            {' '}
            {new Date().getFullYear()}
            {'.'}


        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.dark,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },

    submit: {
        margin: theme.spacing(3, 0, 2),
        color: theme.palette.text.primary,
    },
}));

export default function Form() {


    const classes = useStyles();

    return (

        <Container maxWidth="md">
            <Hero hero="roomsHero">
                <Banner title="Contact us">
                    <Link to="/" className="btn-primary">
                        return home
                    </Link>
                </Banner>
            </Hero>

            <CssBaseline />
            <div className={classes.paper} >
                <div>
                    {/* <h7>Address: 1 Alexander Avenue, Douglasdale,</h7>
                    <h7> Johannesburg, 2000</h7> <br />
                    <h7>Email address: <a href="mailto:reservation@bookmygetaway.co.za">reservation@bookmygetaway</a></h7><br />
                    <h7>Phone: <a href="tel:074 552 2610">074 552 2610</a></h7> */}

                </div>
                <br />
                <Avatar className={classes.avatar}>
                    <Email />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Enquiries
                </Typography>
                <form className={classes.form} validate="true" autoComplete="true" onSubmit={sendEmail} >
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="subject"
                                label="Subject"
                                type="subject"
                                id="subject"
                                autoFocus


                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fistName"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"

                            // error
                            // helperText="Invalid input"

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lastName"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="contact_number"
                                label="Contact Number"
                                type="contact_number"
                                id="contact_number"

                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email-Address"
                                label="Email Address"
                                name="email-Address"
                                autoComplete="email"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField


                                variant="outlined"
                                required
                                fullWidth
                                multiline
                                rows={8}
                                name="message"
                                label="Message"
                                type="message"


                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive, marketing promotions and updates via email."

                            // if value={allowExtraEmails}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"

                        className={classes.submit}
                    >
                        Send
                    </Button>

                </form>



            </div>
            <Box mt={5}>
                <Copyright />
            </Box>



        </Container>

    );
}