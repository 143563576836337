import React from "react";
import './App.css';
// import ReactGa from './reactGa';
import Home from "./pages/Home";
import Units from "./pages/Units";
import ContactUs from "./pages/Contactus";
import Description from "./pages/Description";
import Aboutus from "./pages/Aboutus";

import Error from "./pages/Error";

import { Route, Switch } from 'react-router-dom'

import Navbar from "./components/Navbar";
// import Temp from "./pages/Temp";

function App() {

  // useEffect(() => {
  //   ReactGa.initialize('UA-205374934-1')
  //   //to report page view issues
  //   ReactGa.pageview('/')
  // }, [])

  return (
    <>
      <Navbar />
      <Switch>

        <Route exact path="/" component={Home} />
        <Route exact path="/rooms/" component={Units} />
        <Route exact path="/contactus/" component={ContactUs} />
        <Route exact path="/aboutus/" component={Aboutus} />
        <Route exact path="/rooms/:slug" component={Description} />
        <Route component={Error} />
      </Switch>

    </>
  );
}

export default App;
