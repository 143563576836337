import React from 'react'
import Banner from '../components/Banner';
import Hero from '../components/Hero';
import { Link } from 'react-router-dom'
import Formlead from '../pages/Homeform';
import FeaturedRooms from '../components/FeaturedRooms';

export default function Home() {
    return (
        <>
            <Hero>


                <Banner title="Property Marketing Made Easy." subtitle="Our Passion Is People.">
                    <Link to='/rooms' className="btn-primary">
                        our properties
                    </Link>

                </Banner>
            </Hero>


            <FeaturedRooms />
            <Formlead />


            <div className="featured-rooms-center">
                <h5>Stay informed! Visit the SA Department of Health's website for COVID-19 updates:<a href="http://www.sacoronavirus.co.za" >www.sacoronavirus.co.za</a> </h5>

            </div>
        </>


    )
}
