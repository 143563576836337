import React from 'react';
import { Link } from "react-router-dom";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import Container from '@material-ui/core/Container';

export default function Aboutus() {


    return (
        <Container maxWidth="md">
            <Hero hero="roomsHero">
                <Banner title="About Us">
                    <Link to="/" className="btn-primary">
                        return home
                    </Link>
                </Banner>
            </Hero>

            <div className="about-page">
                <div align="center" >
                    <br />
                    <br />
                    <h7>Address: Griffiths Road , Equestria,  </h7>
                    <br />
                    <h7> Pretoria , 0184</h7><br />
                    <h7>Email address: <a href="mailto:info@propertymarketing.co.za
">info@propertymarketing.co.za
</a></h7><br />
                    <h7>Phone: <a href="tel:0799221342">079 922 1342</a></h7>
                    <br />
                    <br />


                </div>
            </div>

            <div className="about-page">

                <br />
                <p align="center"
                >Property Marketing Services is a unique marketing offering to Property Developers with the specific goal of maximising Project sales in the shortest time period.</p>
                <p align="center">Ensuring each Project has it’s own identity and developing a tangible marketing strategy to generate sales is what we do. </p>
                <p align="center">We make use of cutting edge technology incorporating SEO Services , Google Analytics ,
                    <p>Digital Conversion Optimization and Social Media campaigns ,thus generating project specific leads and therefore Project success.</p></p>
                <p align="center">Our formidable sales team are well experienced in turning property leads into sales.</p>
                <p align="center">Passion , knowledge of Property and the drive to be the best in the business is our key!</p>
            </div>
            <br />



        </Container  >



    );
}